<template>
  <div
    :class="[
      'ez-image-upload',
      { 'ez-image-upload--column': columnMode },
      { 'ez-image-upload--disabled': disabled },
    ]"
  >
    <entity-info
      :imgUrl="imageUrl"
      :imgHasBorder="true"
      :imgBorderRadius="isRound ? '50%' : '.4rem'"
      :imgWidth="imageSize"
      :imgHeight="imageSize"
      @previewClick="onAddImageClick"
    ></entity-info>
    <input
      ref="imageInput"
      type="file"
      accept="image/*"
      :name="imageData ? name : ''"
      @change="previewImage"
    />
    <ez-button
      type="secondary"
      formType="button"
      class="ez-image-upload__add"
      @click="onAddImageClick"
      :data-cy="addButtonDataCy"
    >
      <slot name="addImage">
        <span>Add image</span>
      </slot>
    </ez-button>
    <ez-button
      v-if="canRemoveImage"
      type="link"
      formType="button"
      class="ez-image-upload__remove"
      @click="onRemoveImageClick"
      :data-cy="removeButtonDataCy"
    >
      <slot name="removeImage">
        <font-awesome-icon icon="times" />
        <span>Remove image</span>
      </slot>
    </ez-button>
    <div class="ez-image-upload__error" v-if="hasErrorMessage">{{ error.message }}</div>
  </div>
</template>

<script>
import EzButton from '@/components/ui/Button';
import EntityInfo from '@/components/ui/EntityInfo';

export default {
  name: 'ezimageupload',
  components: {
    EzButton,
    EntityInfo,
  },
  props: {
    /**
     * Key from the parent form
     */
    formKey: {
      required: true,
      type: String,
    },
    /**
     * Input name
     */
    name: {
      required: true,
      type: String,
    },
    /**
     * Image url to show as preview
     */
    previewUrl: {
      required: false,
      type: String,
      default: '',
    },
    /**
     * We need to know id the previewUrl is the default image
     */
    isDefaultImage: {
      required: false,
      type: Boolean,
      default: true,
    },
    /**
     * Control if preview image should be round
     */
    isRound: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Control if component is be disabled
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Control if component elements should be rendered in a column
     */
    columnMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    addButtonDataCy: {
      type: String,
      required: false,
      default: '',
    },
    removeButtonDataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      imageData: null,
      imageRemoved: false,
    };
  },
  computed: {
    imageUrl() {
      return this.imageData || this.previewUrl;
    },
    error() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    hasErrorMessage() {
      return (
        this.error &&
        typeof this.error === 'object' &&
        Object.prototype.hasOwnProperty.call(this.error, 'message')
      );
    },
    canRemoveImage() {
      return this.imageData || !this.isDefaultImage;
    },
    imageSize() {
      return this.columnMode ? '6.5rem' : '4rem';
    },
  },
  watch: {
    imageData(value) {
      /**
       * When image data is changed.
       * @event change
       */
      this.$emit('change', value);
    },
  },
  methods: {
    previewImage() {
      const reader = new FileReader();
      this.reset();
      const image = this.$refs.imageInput.files.length > 0 ? this.$refs.imageInput.files[0] : null;

      if (image == null) {
        return;
      }

      reader.onload = () => {
        this.imageData = reader.result;
      };

      reader.readAsDataURL(image);
    },
    onRemoveImageClick() {
      if (this.disabled) {
        return;
      }

      this.imageData = null;
      this.resetFileInput();
      /**
       * When remove image button is clicked.
       * @event removeImage
       */
      this.$emit('removeImage');
    },
    onAddImageClick() {
      if (this.disabled) {
        return;
      }

      this.$refs.imageInput.click();
      /**
       * When add image button is clicked.
       * @event addImage
       */
      this.$emit('addImage');
    },
    resetFileInput() {
      // Reset input after upload, so files can be added.
      this.$refs.imageInput.files.value = '';
      if (this.$refs.imageInput.files.length && !/safari/i.test(navigator.userAgent)) {
        this.$refs.imageInput.files.type = '';
        this.$refs.imageInput.files.type = 'file';
      }
    },
    reset() {
      this.resetFileInput();
      this.imageData = null;
    },
    /**
     * Use reset()
     * @deprecated 2.3.0
     */
    resetImageData() {
      this.reset();
    },
  },
};
</script>

<style scoped lang="scss">
$error-color: #ea4b5d;

.ez-image-upload {
  @include font-size(14px);
  font-weight: 500;
  display: flex;
  align-items: center;

  input[type='file'] {
    display: none;
  }

  &__add,
  &__remove {
    :deep() svg {
      margin-right: 0.5em;
    }
  }
  &__add {
    margin-left: 1rem;
  }
  &__remove {
    @include font-size(12px);
    font-weight: 400;
  }

  &__error {
    margin-top: 0.5rem;
    color: $error-color;
    @include font-size(12px);
  }

  :deep() .entity-info {
    &:hover {
      cursor: pointer;
    }
    &__preview {
      width: 4rem;
      height: 4rem;
    }
  }

  &--column {
    flex-direction: column;
    .ez-image-upload__add {
      margin-top: 1rem;
      margin-left: 0;
    }
  }

  &--disabled {
    .entity-info {
      &:hover {
        cursor: default;
      }
    }
  }
}
</style>
