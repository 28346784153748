var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"simpleDropdown",class:[
  'ez-simple-dropdown',
  { 'ez-simple-dropdown--disabled': _vm.disabled },
]},[(_vm.label)?_c('v-label',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{class:[
      'ez-simple-dropdown__display-container',
      { 'ez-simple-dropdown__display-container--active': _vm.expanded },
      { 'ez-simple-dropdown__display-container--disabled': _vm.disabled },
      { 'width-100': _vm.isFullWidth }
    ],attrs:{"data-cy":_vm.dataCy},on:{"click":_vm.toggle}},[_vm._t("display",function(){return [_c('div',{staticClass:"ez-simple-dropdown__label"},[_vm._v(_vm._s(_vm.placeholder))]),(_vm.expanded)?_c('font-awesome-icon',{attrs:{"icon":"angle-up"}}):_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})]})],2),_c('div',{staticClass:"ez-simple-dropdown__dropdown-container"},[(_vm.expanded)?_c('div',{staticClass:"ez-simple-dropdown__dropdown",class:`ez-simple-dropdown__options--${_vm.direction}`,on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._t("dropdown")],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }