var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mask-input-wrapper"},[(_vm.label)?_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:[
      'mask-input',
      `mark-input--${_vm.type}`,
      { [`disabled--${_vm.type}`]: _vm.disabled },
      { 'mark-input--error': _vm.error || _vm.hasServerErrorMessage },
      { 'mark-input--invalid': _vm.isInvalid },
    ]},[(!_vm.isInputType && _vm.hasPrefix)?_c('span',{staticClass:"mask-input__prefix"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e(),_c('input',{ref:"inputRef",class:['mask-input__input', { 'mask-input__input--error': _vm.error }],attrs:{"data-cy":_vm.dataCy,"id":_vm.id,"name":_vm.name,"disabled":_vm.disabled,"form-key":_vm.formKey}}),(_vm.hasSuffixSlot)?_c('div',{class:['mask-input__suffix', { 'mask-input__suffix--disabled': _vm.disabled }]},[_vm._t("suffix")],2):_vm._e()]),(_vm.error || _vm.hasServerErrorMessage || _vm.hasErrorSlot)?_c('div',{staticClass:"mark-input__error"},[_vm._t("error",function(){return [(_vm.error || _vm.hasServerErrorMessage)?_c('div',[_vm._v(" "+_vm._s(_vm.error || _vm.serverError.message)+" ")]):_vm._e()]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }