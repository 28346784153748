var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{ref:"file",attrs:{"type":"file","id":_vm.fieldId,"accept":_vm.accept,"multiple":""},on:{"change":_vm.handleFileUpload}}),_c('label',{staticClass:"label",class:[{'disabled': _vm.isUploadLimitReach}],attrs:{"for":_vm.fieldId}},[_vm._t("label")],2),_c('div',{class:[
    'preview',
    {'preview--card': _vm.cardType}
  ]},[_c('div',{staticClass:"preview__images"},_vm._l((_vm.images),function(image){return _c('div',{key:image.id,staticClass:"preview__image"},[(!image.isPDF)?_c('img',{attrs:{"src":image.preview,"alt":image.name,"data-cy":`${_vm.dataCy}-${image.id}-imagePreview`}}):_vm._e(),(image.isPDF)?_c('v-pdf-viewer',{staticClass:"pdf-placeholder cursor-auto",attrs:{"src":image.preview,"data-cy":`${_vm.dataCy}-${image.id}-pdfPreview`,"showAllPages":false}}):_vm._e(),(_vm.cardType)?_c('span',{staticClass:"image-name",attrs:{"data-cy":`${_vm.dataCy}-${image.id}-imageName`}},[_vm._v(" "+_vm._s(image.name)+" ")]):_vm._e(),(_vm.editable)?_c('button',{attrs:{"data-cy":`${_vm.dataCy}-${image.id}-remove`},on:{"click":function($event){return _vm.removeImage(image)}}},[_c('font-awesome-icon',{attrs:{"icon":"times"}})],1):_vm._e()],1)}),0),(_vm.editable)?_c('div',{class:[
      'preview__button',
      {'preview__button--uploaded': _vm.hasUploadedImages},
      {'preview__button--disabled': _vm.isUploadLimitReach},
      ]},[_c('label',{class:[{'disabled': _vm.isUploadLimitReach}],attrs:{"for":_vm.fieldId,"data-cy":_vm.dataCy}},[_vm._t("icon",function(){return [_c('font-awesome-icon',{staticClass:"preview__button-icon",attrs:{"icon":"upload"}})]}),_vm._t("cta",function(){return [_c('span',[_vm._v(_vm._s(_vm.uploadText))])]})],2)]):_vm._e()]),(_vm.hasErrorMessage)?_c('div',{staticClass:"error",attrs:{"data-cy":`${_vm.dataCy}-error`}},[_vm._v(_vm._s(_vm.error.message))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }