<template>
  <div class="mask-input-wrapper">
    <div v-if="label" class="label">{{ label }}</div>
    <div
      :class="[
        'mask-input',
        `mark-input--${type}`,
        { [`disabled--${type}`]: disabled },
        { 'mark-input--error': error || hasServerErrorMessage },
        { 'mark-input--invalid': isInvalid },
      ]"
    >
      <span class="mask-input__prefix" v-if="!isInputType && hasPrefix">
        {{ prefix }}
      </span>
      <!-- This input provides nice UI/UX for the user -->
      <input
        ref="inputRef"
        :class="['mask-input__input', { 'mask-input__input--error': error }]"
        :data-cy="dataCy"
        :id="id"
        :name="name"
        :disabled="disabled"
        :form-key="formKey"
      />
      <div
        :class="['mask-input__suffix', { 'mask-input__suffix--disabled': disabled }]"
        v-if="hasSuffixSlot"
      >
        <slot name="suffix"></slot>
      </div>
    </div>
    <div class="mark-input__error" v-if="error || hasServerErrorMessage || hasErrorSlot">
      <slot name="error">
        <div v-if="error || hasServerErrorMessage">
          {{ error || serverError.message }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
/**
 * EzMaskInputSimple
 * @version 1.0.0
 * @since 2.3.0
 */

import { onMounted, ref, watch } from 'vue';
import { NumberInput } from 'intl-number-input';
import { getCurrency } from '@/util/utils';
import { mapMutations } from 'vuex';
import uuid from 'uuid/v4';

export default {
  name: 'EzMaskInputSimple',
  props: {
    formKey: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    isInvalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * If set to true, ez-form will automatically catch inputs value.
     */
    isFormInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'inline',
      validator: v => ['inline', 'input'].includes(v),
    },
    currency: {
      type: Object,
      required: false,
      default: () => {},
    },
    hasCurrency: {
      type: Boolean,
      required: false,
      default: true,
    },
    pricePrefix: {
      type: Boolean,
      required: false,
      default: true,
    },
    precision: {
      type: Number,
      required: false,
      default: 2,
    },
    masked: {
      type: Boolean,
      required: false,
      default: false,
    },
    maskSuffix: {
      type: String,
      required: false,
      default: '',
    },
    customPrefix: {
      required: false,
      type: String,
      default: () => '',
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
    allowNegativeValue: {
      type: Boolean,
      required: false,
      default: true,
    },
    minValue: {
      type: Number,
      required: false,
      default: Number.MIN_SAFE_INTEGER,
    },
    maxValue: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
  },
  data() {
    return {
      val: 0,
      id: uuid(),
    };
  },
  computed: {
    serverError() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    currencySymbol() {
      if (this.hasCurrency) {
        const { symbol } = getCurrency() || {};
        return this.currency?.symbol || symbol;
      }
      return undefined;
    },
    isInputType() {
      return this.type === 'input';
    },
    hasPrefix() {
      return this.pricePrefix;
    },
    prefix() {
      return this.customPrefix || this.currencySymbol;
    },
    hasServerErrorMessage() {
      return (
        this.serverError &&
        typeof this.serverError === 'object' &&
        Object.prototype.hasOwnProperty.call(this.serverError, 'message')
      );
    },
    hasSuffixSlot() {
      return !!this.$slots.suffix;
    },
    hasErrorSlot() {
      return !!this.$slots.error;
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERROR']),
    checkIsValid(val) {
      const over = val > this.maxValue;
      const under = val < (!this.allowNegativeValue ? 0 : this.minValue);
      this.$emit('invalid', over || under);
    },
    async clearErrors() {
      await this.$nextTick();
      await this.$validator.reset();
      this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
  },
  watch: {
    minValue() {
      this.checkIsValid(this.val);
    },
    maxValue() {
      this.checkIsValid(this.val);
    },
    value(newVal) {
      this.checkIsValid(newVal);
      this.val = newVal;
    },
  },

  emits: ['input'],
  setup(props, { emit }) {
    let numberInput;
    const inputRef = ref(null);
    onMounted(() => {
      if (inputRef.value) {
        numberInput = new NumberInput({
          el: inputRef.value,
          options: {
            formatStyle: 'decimal',
            precision: props.precision ? { min: 0, max: props.precision } : 0,
            valueRange: { min: props.minValue, max: props.maxValue },
          },
          onInput: value => {
            emit('input', value.number);
          },
        });
        numberInput.setValue(props.value);
      }
    });

    watch(
      () => props.value,
      newVal => {
        if (numberInput) numberInput.setValue(newVal);
      },
      { immediate: true },
    );

    watch(
      () => props.maxValue,
      newVal => {
        if (numberInput)
          numberInput.setOptions({
            formatStyle: 'decimal',
            precision: props.precision ? { min: 0, max: props.precision } : 0,
            valueRange: { min: props.minValue, max: newVal },
          });
      },
    );

    watch(
      () => props.minValue,
      newVal => {
        if (numberInput)
          numberInput.setOptions({
            formatStyle: 'decimal',
            precision: props.precision ? { min: 0, max: props.precision } : 0,
            valueRange: { min: newVal, max: props.maxValue },
          });
      },
    );

    return { inputRef };
  },
};
</script>

<style lang="scss" scoped>
.mask-input-wrapper {
  width: 100%;
  .label {
    color: $color-gray-6C;
    @include font-size(12px, 18px);
    margin-bottom: 6px;
  }
  .mask-input {
    position: relative;
    width: 75px;

    &__prefix {
      position: absolute;
      left: 0;
      top: 0;
    }
    &__suffix {
      position: absolute;
      text-align: center;
      z-index: 5;
      @include font-size(14px, 20px);
      color: $color-gray-6C;
      right: 8px;
      &--disabled {
        opacity: 0.5;
      }
    }

    &__input {
      @include font-size(14px);
      background: transparent;
      width: 100%;
      padding-left: 10px;
      border: 0;
      text-align: right;
      border-bottom: 1px solid $input-border-color;
      -moz-appearance: textfield;
      color: $color-primary-blue;
      font-weight: bold;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus {
        outline: none;
        border-bottom: 1px solid #4d7cfe;
      }
      &--error,
      &--error:focus {
        border-bottom: 1px solid $input-border-error-color;
      }
    }
  }

  .mark-input--input {
    display: flex;
    align-items: center;
    width: 100%;
    height: $input-height;
    padding: 0 $input-padding-x;
    border-radius: $input-border-radius;
    border: 1px solid $input-border-color;

    .mask-input__input {
      padding: 0;
      border: 0;
      text-align: left;
      color: #252631;
    }
  }
  .mark-input {
    &--error,
    &--error:focus {
      border: 1px solid $input-border-error-color;
    }
    &__error {
      margin-top: 8px;
      color: $input-border-error-color;
      @include font-size(12px);
    }

    &--invalid {
      .mask-input {
        &__input,
        &__input:focus {
          border-bottom: 1px solid $input-border-error-color;
          color: $input-border-error-color;
        }
      }
    }
  }
  .disabled--inline {
    opacity: 0.5;
    pointer-events: none;
  }
  .disabled--input {
    background-color: $input-disabled-bg-color;
    pointer-events: none;
    cursor: not-allowed;
    border: 0;
    .mask-input__input {
      color: $input-disabled-color;
      &::placeholder {
        color: $input-disabled-color;
      }
    }
  }
}
</style>
