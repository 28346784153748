<template>
  <div ref="simpleDropdown" :class="[
    'ez-simple-dropdown',
    { 'ez-simple-dropdown--disabled': disabled },
  ]">
    <v-label v-if="label">
      {{ label }}
    </v-label>
    <div
      :class="[
        'ez-simple-dropdown__display-container',
        { 'ez-simple-dropdown__display-container--active': expanded },
        { 'ez-simple-dropdown__display-container--disabled': disabled },
        { 'width-100': isFullWidth }
      ]"
      @click="toggle"
      :data-cy="dataCy"
    >
      <slot name="display">
        <div class="ez-simple-dropdown__label">{{ placeholder }}</div>
        <font-awesome-icon v-if="expanded" icon="angle-up"/>
        <font-awesome-icon v-else icon="angle-down"/>
      </slot>
    </div>
    <div class="ez-simple-dropdown__dropdown-container">
      <div
        v-if="expanded"
        class="ez-simple-dropdown__dropdown"
        :class="`ez-simple-dropdown__options--${direction}`"
        @click.stop="close"
      >
        <slot name="dropdown"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import VLabel from '@/components/v3/elements/VLabel';

export default {
  components: {
    VLabel,
  },
  props: {
    direction: {
      type: String,
      default: 'down',
      required: false,
      validator: value => ['up', 'down'].indexOf(value) !== -1,
    },
    placeholder: {
      type: String,
      required: false,
    },
    /**
     * Propagate Event.
     * Useful inside a table when a table has a click event.
     */
    stopPropagation: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    isFullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return { expanded: false };
  },
  methods: {
    toggle(e) {
      if (this.disabled) return;
      if (this.stopPropagation) {
        e.stopPropagation();
      }
      if (this.expanded) this.close();
      else this.open();
    },
    close() {
      this.expanded = false;
      this.$emit('close');
    },
    open() {
      this.expanded = true;
      this.$emit('open');
    },
    documentClick(e) {
      if (!this.$el.contains(e.target)) {
        this.close();
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.documentClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.documentClick);
  },
};
</script>

<style scoped lang="scss">
  $input-height: 2.25rem;
  $input-width: px-to-rem(129px);
  $border-radius: .1875rem;
  $padding-x: px-to-rem(14px);
  $padding-y: 0;

  $label-color:               #6C7995;
  $label-font-size:           14px;
  $label-line-height:         14px;
  $label-letter-spacing:      0.025rem;

  .ez-simple-dropdown {
    width: $input-width;
    line-height: $input-height;
    :deep() > label {
      display: block;
      color: $label-color;
      @include font-size(12px, $label-line-height);
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: 6px;
    }
    &__label {
      color: #6C7995;
      @include font-size(14px, 14px);
      font-weight: 500;
      text-transform: capitalize;
      padding: 11px 0;
    }
    &__dropdown {
      position: absolute;
      top: $dropdown-top;
      max-height: 20rem;
      overflow-y: auto;
      border: 1px solid #DEE1E4;
      background-color: $color-white;
      border-radius: $border-radius;
      padding: $dropdown-padding-y $dropdown-padding-x;
      box-shadow: $dropdown-shadow;
      width: 100%;
    }
    &__dropdown-container {
      position: relative;
      z-index: 1000;
    }
    &__options--up {
      top: auto;
      bottom: 100%;
      margin-bottom: px-to-rem(42px);
    }
    &__display-container {
      transition: background-color .2s ease-in-out;
      @extend %flex-center;
      justify-content: space-between;
      background-color: $color-gray-F5;
      padding: $padding-y $padding-x;
      border-radius: $border-radius;
      cursor: pointer;

      &:hover {
        background-color: $color-gray-E9;
      }

      svg { color: $color-gray-6C; }
      &--disabled {
        cursor: not-allowed;
      }
    }
    &--disabled {
      opacity: .48;
    }
  }
</style>
