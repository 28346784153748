var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mask-input-wrapper"},[(_vm.label)?_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:[
      'mask-input',
      `mark-input--${_vm.type}`,
      {[`disabled--${_vm.type}`]: _vm.disabled},
      {'mark-input--error': _vm.error || _vm.hasServerErrorMessage },
      {'mark-input--invalid': _vm.isInvalid },
     ]},[(!_vm.isInputType && _vm.hasPrefix)?_c('span',{staticClass:"mask-input__prefix"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e(),_c('money',_vm._b({class:[
      'mask-input__input',
      {'mask-input__input--error': _vm.error},
    ],attrs:{"value":_vm.val,"data-cy":_vm.dataCy},on:{"input":_vm.onInput}},'money',_vm.money,false)),(_vm.isFormInput)?_c('input',{attrs:{"id":_vm.id,"name":_vm.name,"type":"hidden","disabled":_vm.disabled,"form-key":_vm.formKey},domProps:{"value":_vm.val}}):_vm._e(),(_vm.isInputType && _vm.hasSuffixSlot)?_c('div',{class:[
        'mask-input__suffix',
        { 'mask-input__suffix--disabled': _vm.disabled },
      ]},[_vm._t("suffix")],2):_vm._e()],1),_c('div',{staticClass:"mark-input__error"},[_vm._t("error",function(){return [(_vm.error || _vm.hasServerErrorMessage)?_c('div',[_vm._v(" "+_vm._s(_vm.error || _vm.serverError.message)+" ")]):_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }