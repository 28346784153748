var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ez-wizard"},[_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v(_vm._s(_vm.loadingMsg))]),_c('header',[_c('div',{staticClass:"ez-wizard__logo"},[_c('router-link',{attrs:{"to":{ name: _vm.home }}},[(_vm.oezPlatformName === 'Foodrazor')?_c('img',{attrs:{"src":require("@/assets/Foodrazor-logo.svg"),"alt":"Foodrazor logo"}}):_c('img',{attrs:{"src":require("@/assets/logo-dark.svg"),"alt":"EZ logo"}})]),_c('div',{staticClass:"ez-wizard__title"},[_vm._t("title")],2)],1),_c('nav',{class:['ez-wizard__nav', { 'ez-wizard__nav--hide': _vm.isLastStep }]},[_vm._t("nav")],2),_c('div',{staticClass:"ez-wizard__actions"},[_vm._t("actions"),_c('div',{staticClass:"ez-wizard__exit"},[_c('button',{on:{"click":_vm.exit}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16"}},[_c('path',{attrs:{"d":"M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"}})]),_c('small',[_vm._v("ESC")])])])],2)]),_c('main',{class:[
      'ez-wizard__main',
      { 'ez-wizard__main--wide': _vm.extraWide },
      { 'ez-wizard__main--medium': _vm.mediumWide },
    ]},[_c('div',{staticClass:"ez-wizard__page-banner"},[_vm._t("pageBanner")],2),_c('div',{class:[
        'ez-wizard__main-container',
        { 'ez-wizard__main-container--narrow': !_vm.fullWidth && !_vm.extraWide && !_vm.mediumWide },
        { 'ez-wizard__main-container--wide': _vm.extraWide },
        { 'ez-wizard__main-container--medium': _vm.mediumWide },
      ]},[_c('div',{staticClass:"ez-wizard__page-header"},[_c('div',{staticClass:"ez-wizard__page-title"},[_c('div',{staticClass:"ez-wizard__prev-step"},[_vm._t("prevStep")],2),_c('h2',[_vm._t("pageTitle")],2)]),_c('p',[_vm._t("pageInfo")],2)]),_vm._t("default")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }