<template>
  <div v-if="isBadgeStatus" :class="`status-badge status--${status}`">
    <span v-if="$slots.prefix" class="status-badge__prefix mr-4"><slot name="prefix" /></span>
    <span :class="`status-badge__status status-badge__status--${status}`" />
    <span class="status-badge__text" :data-cy="dataCy">{{ statusLabels[status] || '' }}</span>
    <span v-if="$slots.postfix" class="status-badge__postfix ml-4"><slot name="postfix" /></span>
  </div>
  <div v-else :class="`status status--${status}`">
    <span v-if="$slots.prefix" class="status-badge__prefix mr-4"><slot name="prefix" /></span>
    <span :data-cy="dataCy">{{ statusLabels[status] || '' }}</span>
    <span v-if="$slots.postfix" class="status-badge__postfix ml-4"><slot name="postfix" /></span>
  </div>
</template>

<script>
import {
  ORDER_STATUS_QUOTE,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_SENT,
  ORDER_STATUS_RECEIVED,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_NEEDS_APPROVAL,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_IN_DISPUTE,
  ORDER_STATUS_ABANDONED,
  ORDER_STATUS_BACKORDER,
  ORDER_STATUS_VENUE_DRAFT,
  ORDER_STATUS_DISTRIBUTOR_DRAFT,
  CREDIT_NOTE_STATUS_DRAFT,
  CREDIT_NOTE_STATUS_APPROVED,
  OUTLET_GROUP_PRIORITY_STANDARD,
  OUTLET_GROUP_PRIORITY_VIP,
  OUTLET_GROUP_PRIORITY_TRIALIST,
  OUTLET_GROUP_PRIORITY_PRIORITY,
  OUTLET_GROUP_PRIORITY_HIDDEN,
  OUTLET_GROUP_PRIORITY_LOW,
  ACCOUNT_STATUS_ACTIVE,
  ACCOUNT_STATE_NEW,
  PRODUCT_STATE_ADDED,
  ACCOUNT_STATUS_TRIAL_ENDING,
  ACCOUNT_STATUS_TRIAL_EXPIRED,
  ACCOUNT_STATUS_DEACTIVATED,
  ACCOUNT_STATUS_DRAFT,
  ACCOUNT_STATUS_INVITE_SENT,
  statusLabels,
  creditNoteStatusLabels,
  outletGroupPriorityStatusLabels,
  invoiceProcessingStatusLabels,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
  QUANTITY_STATUS_OUT_OF_STOCK,
  QUANTITY_STATUS_NOT_TRACKING,
  PRODUCT_VISIBILITY_VISIBLE,
  PRODUCT_VISIBILITY_HIDDEN,
  PENDING_UPDATES_STATUS_ACTIVE,
  PENDING_UPDATES_STATUS_REQUEST_PENDING,
  pendingUpdatesStatusLabels,
  accountStatusLabels,
  adminInvoiceProcessingStatusLabels,
  TARGET_ACCOUNT,
  PENDING_ACCOUNT_OPENING,
  ACCOUNT_OPEN_YET_TO_BUY,
  NOT_ORDERING_ACCOUNT,
  ORDERING_ACCOUNT,
  NO_SPENDINGS,
  ExpenseType,
  ExpenseTypeLabels,
  ActivityType,
  ActivityTypeLabels,
  CURRENCY_NOT_MATCHING,
  PENDING_INVITE_SENT,
  PRIMARY_CONTACT_LABEL,
  PRIMARY_CONTACT_LABEL_DEFAULT,
  ACCOUNT_TYPE_PREMIUM,
  WAREHOUSE_PRIMARY_DEFAULT,
  taxCalucationStatuLables,
  TAX_CALCULATION_EXCLUSIVE,
  TAX_CALCULATION_INCLUSIVE,
  TAX_CALCULATION_EXEMPT,
  PRODUCT_TRACKING_ASSIGN,
  PRODUCT_NOT_TRACKED,
  PRODUCT_TRACKED,
} from '@/util/constants';

export default {
  props: {
    status: {
      type: String,
      required: true,
      validator: value =>
        [
          ORDER_STATUS_QUOTE,
          ORDER_STATUS_PENDING,
          ORDER_STATUS_SENT,
          ORDER_STATUS_RECEIVED,
          ORDER_STATUS_PARTIALLY_ACCEPTED,
          ORDER_STATUS_ACCEPTED,
          ORDER_STATUS_DECLINED,
          ORDER_STATUS_SHIPPED,
          ORDER_STATUS_NEEDS_APPROVAL,
          ORDER_STATUS_COMPLETED,
          ORDER_STATUS_IN_DISPUTE,
          ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
          ORDER_STATUS_ABANDONED,
          ORDER_STATUS_BACKORDER,
          ORDER_STATUS_VENUE_DRAFT,
          ORDER_STATUS_DISTRIBUTOR_DRAFT,

          CREDIT_NOTE_STATUS_DRAFT,
          CREDIT_NOTE_STATUS_APPROVED,

          OUTLET_GROUP_PRIORITY_STANDARD,
          OUTLET_GROUP_PRIORITY_VIP,
          OUTLET_GROUP_PRIORITY_TRIALIST,
          OUTLET_GROUP_PRIORITY_PRIORITY,
          OUTLET_GROUP_PRIORITY_HIDDEN,
          OUTLET_GROUP_PRIORITY_LOW,

          ACCOUNT_STATUS_ACTIVE,
          ACCOUNT_STATE_NEW,
          ACCOUNT_STATUS_TRIAL_ENDING,
          ACCOUNT_STATUS_TRIAL_EXPIRED,
          ACCOUNT_STATUS_DEACTIVATED,
          ACCOUNT_STATUS_DRAFT,
          ACCOUNT_STATUS_INVITE_SENT,

          PRODUCT_STATE_ADDED,

          QUANTITY_STATUS_OUT_OF_STOCK,
          QUANTITY_STATUS_NOT_TRACKING,

          PRODUCT_VISIBILITY_VISIBLE,
          PRODUCT_VISIBILITY_HIDDEN,

          PENDING_UPDATES_STATUS_ACTIVE,
          PENDING_UPDATES_STATUS_REQUEST_PENDING,
          PENDING_INVITE_SENT,

          ...Object.values(ExpenseType),
          ...Object.values(ActivityType),
          ...Object.keys(invoiceProcessingStatusLabels),
          ...Object.keys(adminInvoiceProcessingStatusLabels),

          TARGET_ACCOUNT,
          PENDING_ACCOUNT_OPENING,
          ACCOUNT_OPEN_YET_TO_BUY,
          NOT_ORDERING_ACCOUNT,
          ORDERING_ACCOUNT,
          NO_SPENDINGS,

          CURRENCY_NOT_MATCHING,

          PRIMARY_CONTACT_LABEL,
          PRIMARY_CONTACT_LABEL_DEFAULT,

          ACCOUNT_TYPE_PREMIUM,
          WAREHOUSE_PRIMARY_DEFAULT,

          TAX_CALCULATION_EXCLUSIVE,
          TAX_CALCULATION_INCLUSIVE,
          TAX_CALCULATION_EXEMPT,

          PRODUCT_TRACKING_ASSIGN,
          PRODUCT_NOT_TRACKED,
          PRODUCT_TRACKED,
          'open',
          'due',
          'overdue',
        ].includes(value),
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    statusLabels() {
      return {
        ...statusLabels,
        ...creditNoteStatusLabels,
        ...outletGroupPriorityStatusLabels,
        [OUTLET_GROUP_PRIORITY_LOW]: 'Low',
        [OUTLET_GROUP_PRIORITY_HIDDEN]: 'Hidden',
        ...invoiceProcessingStatusLabels,
        [ACCOUNT_STATUS_ACTIVE]: 'Active',
        [ACCOUNT_STATE_NEW]: 'New',
        [PRODUCT_STATE_ADDED]: 'Added',
        [ACCOUNT_STATUS_TRIAL_ENDING]: 'Trial ending',
        [ACCOUNT_STATUS_TRIAL_EXPIRED]: 'Trial expired',
        [ACCOUNT_STATUS_DEACTIVATED]: 'Deactivated',
        [ACCOUNT_STATUS_DRAFT]: 'Draft',
        [ACCOUNT_STATUS_INVITE_SENT]: 'Invite sent',
        [QUANTITY_STATUS_OUT_OF_STOCK]: 'Out of stock',
        [QUANTITY_STATUS_NOT_TRACKING]: 'Not tracking',
        [PRODUCT_VISIBILITY_VISIBLE]: 'Visible',
        [PRODUCT_VISIBILITY_HIDDEN]: 'Hidden',
        [PRODUCT_TRACKING_ASSIGN]: 'Assign',
        [PRODUCT_NOT_TRACKED]: 'Not Tracked',
        [PRODUCT_TRACKED]: 'Tracked',
        [CURRENCY_NOT_MATCHING]: 'Currency not matching',
        ...ExpenseTypeLabels,
        ...ActivityTypeLabels,
        ...pendingUpdatesStatusLabels,
        ...taxCalucationStatuLables,
        ...accountStatusLabels,
        ...adminInvoiceProcessingStatusLabels,
        [PRIMARY_CONTACT_LABEL]: 'Primary',
        [PRIMARY_CONTACT_LABEL_DEFAULT]: 'Default Primary',
        [ACCOUNT_TYPE_PREMIUM]: 'Premium',
        [WAREHOUSE_PRIMARY_DEFAULT]: 'Default',
        [ORDER_STATUS_SENT]: 'Sent',
        [ORDER_STATUS_RECEIVED]: 'Received',
        open: 'Open',
        due: 'Due',
        overdue: 'Overdue',
      };
    },
    isBadgeStatus() {
      return [
        ORDER_STATUS_PARTIALLY_ACCEPTED,
        ORDER_STATUS_ACCEPTED,
        ORDER_STATUS_DECLINED,
        ORDER_STATUS_SHIPPED,
        ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
        ORDER_STATUS_ABANDONED,
        ORDER_STATUS_BACKORDER,

        ACCOUNT_STATUS_INVITE_SENT,

        PENDING_UPDATES_STATUS_REQUEST_PENDING,
      ].includes(this.status);
    },
  },
};
</script>

<style scoped lang="scss">
.status-badge {
  display: inline-flex;
  align-items: center;
  background-color: rgba(108, 121, 149, 0.12);
  color: $color-gray-6C;
  padding: 0.125rem 0.375rem;
  border-radius: 0.125rem;
  max-width: 100%;
  vertical-align: middle;

  &__text {
    @include font-size(11px);
    font-weight: 600;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__status {
    display: inline-block;
    border-radius: 50%;
    margin-right: 0.25rem;
    flex-shrink: 0;
    width: 0.25rem;
    height: 0.25rem;
    background-color: $color-gray-6C;
    &--accepted {
      background-color: $color-primary-green;
    }
    &--partially_accepted,
    &--invite_sent,
    &--connection_pending {
      background-color: $color-primary-blue;
    }
    &--completed_after_dispute,
    &--declined {
      background-color: $color-primary-red;
    }
    &--out_for_delivery,
    &--shipped {
      background-color: $color-primary-orange;
    }
    &--backorder {
      background-color: $color-primary-purple;
    }
  }
}
.status {
  @include font-size(11px);
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.125rem 0.375rem;
  border-radius: 0.125rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &--invitation_sent,
  &--currency-no-matching {
    border: 1px solid $color-gray-E1;
    background-color: $color-white;
    color: $color-gray-6C;
  }

  &--default,
  &--primary,
  &--todo,
  &--business_development,
  &--processing,
  &--quote,
  &--exempt,
  &--trialist,
  &--in_clearing,
  &--in_review,
  &--added,
  &--open {
    background-color: $color-pastel-blue;
    color: $color-primary-blue;
  }

  &--travel,
  &--premium,
  &--pending,
  &--sent,
  &--trial_ending,
  &--due,
  &--low {
    background-color: rgba($color-primary-orange, 0.16);
    color: $color-primary-orange;
  }

  &--meeting {
    background-color: rgba($color-primary-carrot, 0.16);
    color: $color-primary-carrot;
  }

  &--check_in,
  &--needs_approval,
  &--standard,
  &--exclusive,
  &--clearing_ready,
  &--review_ready {
    background-color: rgba($color-primary-purple, 0.16);
    color: $color-primary-purple;
  }

  &--completed_after_dispute,
  &--completed,
  &--received,
  &--approved,
  &--active,
  &--new,
  &--visible,
  &--tracked,
  &--inclusive,
  &--active,
  &--call,
  &--processed,
  &--vip {
    background-color: rgba($color-primary-green, 0.16);
    color: $color-primary-green;
  }
  &--in_dispute,
  &--deactivated,
  &--out_of_stock,
  &--action_needed,
  &--trial_expired,
  &--not_tracked,
  &--priority,
  &--overdue {
    background-color: rgba($color-primary-red, 0.16);
    color: $color-primary-red;
  }

  &--other,
  &--target_account,
  &--pending_account_opening,
  &--account_open,
  &--not_ordering_account,
  &--ordering_account,
  &--no_spendings,
  &--draft,
  &--draft_venue,
  &--draft_distributor,
  &--not_tracking,
  &--hidden,
  &--assign,
  &--primary_default {
    background-color: rgba($color-gray-6C, 0.16);
    color: #6c7995;
  }
}
</style>
