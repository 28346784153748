<template>
  <div
    v-on="!disabled ? { click: toggle } : {}"
    ref="select" class="ez-select"
    :class="[{'ez-select--full-width': isFullWidth }, {'ez-select--disabled' : disabled}]"
    :data-cy="dataCy"
  >
    <label class="ez-select__label" :for="id" v-if="label">{{ label }}</label>
    <select class="ez-select__select" :disabled="disabled" :name="name" :id="id">
      <option
        v-for="option in options"
        :key="option[valueField]"
        :value="option[valueField]"
        :selected="selectedOption && option[valueField] === selectedOption[valueField]">
        {{ option[nameField] }}
      </option>
    </select>
    <div
      :class="[
        'ez-select__display-container',
        { border : expanded },
      ]">
      <slot name="display">
        <div :class="[
          'ez-select__display',
          {'ez-select__display--placeholder': !selectedOption[valueField]}
        ]">{{ selectedOption && selectedOption[nameField] }}</div>
        <font-awesome-icon v-if="expanded" icon="angle-up"/>
        <font-awesome-icon v-else icon="angle-down"/>
      </slot>
    </div>
    <div class="ez-select__dropdown-container">
      <div v-if="expanded" class="ez-select__dropdown">
        <slot>
          <ez-option
            v-for="option in options"
            :key="option[valueField]"
            :option="option"
            :selected="selectedOption && option[valueField] === selectedOption[valueField]">
            {{ option[nameField] }}
          </ez-option>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import EzOption from './EzOption.vue';
import { SELECT_OPTION } from './constants';

export default {
  components: {
    EzOption,
  },
  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
    valueField: {
      type: String,
      default: 'id',
    },
    nameField: {
      type: String,
      default: 'name',
    },
    value: {
      type: [Number, String, Object],
      default: () => null,
    },
    selected: {
      type: [Number, String],
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  provide() {
    return {
      [SELECT_OPTION]: this.setValue,
    };
  },
  data() {
    return {
      id: null,
      expanded: false,
      selectedOption: null,
      originalValue: null,
      eventListeners: false,
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
      if (this.expanded) this.setupEventListeners();
      else this.removeEventListeners();
    },
    clickOutsideListener(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.expanded = false;
      }
    },
    setupEventListeners() {
      if (this.eventListeners) return;
      this.eventListeners = true;
      document.addEventListener('click', this.clickOutsideListener, true);
    },
    removeEventListeners() {
      this.eventListeners = false;
      document.removeEventListener('click', this.clickOutsideListener, true);
    },
    setValue(option, emitEvent = true) {
      this.selectedOption = option;

      if (emitEvent) {
        this.$emit('change', this.selectedOption);
      }
    },
    reset() {
      this.setValue(this.originalValue, false);
    },
    documentClick(e) {
      if (!this.$refs.select.contains(e.target)) {
        this.expanded = false;
      }
    },
    onCreated() {
      const firstOption = this.options.length ? this.options[0] : null;
      const value = typeof this.value === 'object' ? this.value : this.options.find(o => o[this.valueField] === this.value);
      this.selectedOption = value || firstOption;
      this.originalValue = this.selectedOption;
    },
  },
  watch: {
    selected() {
      const firstOption = this.options.length ? this.options[0] : null;
      const value = typeof this.selected === 'object' ? this.selected : this.options.find(o => o[this.valueField] === this.selected);

      this.selectedOption = value || firstOption;
      this.originalValue = this.selectedOption;
    },
    options() {
      this.onCreated();
    },
  },
  created() {
    this.onCreated();
  },
  mounted() {
    this.id = uuid();
    window.addEventListener('click', this.documentClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.documentClick);
  },
};
</script>

<style scoped lang="scss">
  $input-height: 2.25rem;
  $input-width: 12.5rem;
  $border-radius: .1875rem;
  $border-width: .125rem;
  $padding-x: .75rem;
  $padding-y: 0;
  $dropdown-shadow: 0 8px 6px -4px rgba(0, 0, 0, 0.12);
  $dropdown-top: .5rem;
  $dropdown-padding-x: 0;
  $dropdown-padding-y: .25rem;
  $option-padding-x: 1rem;
  $option-padding-y: 0;
  $label-color: #6C7995;
  $label-font-size: 12px;
  $label-line-height: 14px;
  $label-letter-spacing: 0.025rem;
  $selected-border-color: #4D7CFE;

  .ez-select {
    width: $input-width;
    line-height: $input-height;
    cursor: pointer;
    box-sizing: content-box;

    &--full-width { width: 100%; }

    ul {
      @extend %ul-reset;
    }

    &__select {
      display: none;
    }

    &__label {
      display: block;
      margin-bottom: .375rem;
      color: $label-color;
      @include font-size($label-font-size, $label-line-height);
      font-weight: 500;
      text-transform: capitalize;
    }

    &__dropdown {
      position: absolute;
      top: $dropdown-top;
      max-height: 20rem;
      overflow-y: auto;
      border: 1px solid #DEE1E4;
      background-color: $color-white;
      border-radius: $border-radius;
      padding: $dropdown-padding-y $dropdown-padding-x;
      box-shadow: $dropdown-shadow;
      width: 100%;
      box-sizing: content-box;
    }

    &__dropdown-container {
      position: relative;
      z-index: 1000;
    }

    &__display-container {
      @extend %flex-center;
      justify-content: space-between;
      background-color: $color-gray-F5;
      padding: $padding-y $padding-x;
      border-radius: $border-radius;
      height: 100%;
      &:hover {
        background-color: #E9EBF2;
        transition: background-color .2s ease-in-out;
      }
    }

    &__display {
      @include font-size(14px, 16px);
      padding: .5rem 0;
      border-radius: $border-radius;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;

      &--placeholder { color: $label-color; }
    }

    :deep() .ez-option {
      padding: $option-padding-y $option-padding-x;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;

      &:hover {
        background-color: $color-gray-F5;
      }

      &--disabled {
        cursor: default;
        opacity: .5;
        &:hover { background-color: transparent; }
      }
    }

    &--disabled {
      pointer-events: none;

      .ez-select__display-container,
      .ez-select__display { color: #B4B8C2}
    }
  }
  .border {
    box-shadow: 0 0 0 $border-width $selected-border-color;
    border-radius: $border-radius;
  }
</style>
