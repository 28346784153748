<template>
  <div>
    <input
      type="file"
      :id="fieldId"
      ref="file"
      @change="handleFileUpload"
      :accept="accept"
      multiple
    >
    <label class="label" :for="fieldId" :class="[{'disabled': isUploadLimitReach}]">
      <slot name="label"/>
    </label>

    <div :class="[
      'preview',
      {'preview--card': cardType}
    ]">
      <div class="preview__images">
        <div class="preview__image" v-for="image in images" :key="image.id">
          <img
            v-if="!image.isPDF"
            :src="image.preview"
            :alt="image.name"
            :data-cy="`${dataCy}-${image.id}-imagePreview`"
          />
          <v-pdf-viewer
            v-if="image.isPDF"
            :src="image.preview"
            class="pdf-placeholder cursor-auto"
            :data-cy="`${dataCy}-${image.id}-pdfPreview`"
            :showAllPages="false"
          />

          <span
            v-if="cardType"
            class="image-name"
            :data-cy="`${dataCy}-${image.id}-imageName`"
          >
            {{ image.name }}
          </span>

          <button
            v-if="editable"
            @click="removeImage(image)"
            :data-cy="`${dataCy}-${image.id}-remove`"
          >
            <font-awesome-icon icon="times"/>
          </button>
        </div>
      </div>
      <div v-if="editable" :class="[
        'preview__button',
        {'preview__button--uploaded': hasUploadedImages},
        {'preview__button--disabled': isUploadLimitReach},
        ]">
        <label :for="fieldId" :class="[{'disabled': isUploadLimitReach}]" :data-cy="dataCy">
          <slot name="icon"><font-awesome-icon icon="upload" class="preview__button-icon"/></slot>
          <slot name="cta"><span>{{ uploadText }}</span></slot>
        </label>
      </div>
    </div>
    <div class="error" v-if="hasErrorMessage" :data-cy="`${dataCy}-error`">{{ error.message }}</div>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapMutations, mapGetters } from 'vuex';
import VPdfViewer from '@/components/v3/elements/VPdfViewer';
/**
   * Component to upload multiple images and list them.
   * @version 1.0.0
   * @since
   */
export default {
  name: 'ezmultipleimageupload',
  components: {
    VPdfViewer,
  },
  props: {
    /**
     * Key from the parent form
     */
    formKey: {
      required: false,
      type: String,
    },
    name: {
      type: String,
      required: false,
      default: 'images',
    },
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    numberOfFiles: {
      type: Number,
      required: false,
      default: 5,
    },
    accept: {
      type: String,
      required: false,
      default: 'image/*',
    },
    cardType: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    customError: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      images: [],
      fieldId: '',
      dropBoxActive: false,
    };
  },
  computed: {
    ...mapGetters('errors', [
      'getError',
    ]),
    hasUploadedImages() {
      return this.images.length > 0;
    },
    isUploadLimitReach() {
      return this.images.length >= this.numberOfFiles;
    },
    uploadText() {
      return this.hasUploadedImages ? 'Add Another Image' : 'Add Image';
    },
    formData() {
      const form = new FormData();
      this.images.forEach(img => form.append(this.name, img.file));
      return form;
    },
    error() {
      return this.getError(this.formKey, this.name) || this.customError;
    },
    hasErrorMessage() {
      return this.error
        && typeof this.error === 'object'
        && Object.prototype.hasOwnProperty.call(this.error, 'message');
    },
  },
  methods: {
    numberOfFilesLeft() {
      return this.numberOfFiles - this.images.length;
    },
    removeImage({ id }) {
      this.images = this.images.filter(img => img.id !== id);
      this.$emit('change', this.formData);
      if (!this.images.length) this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
    async handleFileUpload() {
      await this.generatePreview(this.$refs.file.files);
      this.$emit('change', this.formData);
      this.resetFileInput();
    },
    async handleExternalFileUpload(e) {
      await this.generatePreview(e.dataTransfer.files);
      this.$emit('change', this.formData);
      this.resetFileInput();
    },
    generatePreview(images) {
      const imgPromises = [];
      for (let i = 0; i < Math.min(this.numberOfFilesLeft(), images.length); i += 1) {
        const reader = new FileReader();
        reader.readAsDataURL(images[i]);
        imgPromises.push(new Promise((resolve) => {
          reader.onload = () => {
            this.images.push({
              id: uuid(),
              name: images[i].name,
              preview: reader.result,
              file: images[i],
              isPDF: images[i].type.split('/').pop() === 'pdf',
            });
            resolve();
          };
        }));
      }
      return Promise.all(imgPromises);
    },
    resetFileInput() {
      // Reset input after upload, so files can be added.
      this.$refs.file.value = '';
      if (this.$refs.file.files.length && !/safari/i.test(navigator.userAgent)) {
        this.$refs.file.type = '';
        this.$refs.file.type = 'file';
      }
    },
    reset() {
      this.resetFileInput();
      this.images = [];
      this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
    async addFile(e) {
      this.dropBoxActive = false;
      if (!e.dataTransfer.files) return;
      await this.generatePreview(e.dataTransfer.files);
      this.$emit('change', this.formData);
      this.resetFileInput();
    },
    ...mapMutations('errors', [
      'CLEAR_ERROR',
    ]),
  },
  mounted() {
    this.fieldId = uuid();
    if (this.value.length) {
      this.generatePreview(this.value);
    }
  },
};
</script>

<style lang="scss" scoped>
  :deep() .pdf-page-canvas {
    width: 100%;
    height: 100%;
  }
  .drop-box-active {
    border: 2px dashed $color-gray-6C;
    border-radius: 4px;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      background-color: rgba(#6c7995, .3);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  .preview {
    @extend %flex-center;

    label {
      @extend %base-button;
      @include button-color(
          $button-secondary-bg,
          $button-secondary-color,
          $button-secondary-hover-bg,
          $button-secondary-bg);
    }

    &__images {
      @extend %flex-center;
    }

    &__image {
      position: relative;
      @include size(36px);
      border: 1px solid #DEE1E4;
      border-radius: $border-radius;
      overflow: hidden;

      img {
        @include size(100%);
        object-fit: cover;
      }

      button {
        @include absolute(0);
        @extend %flex-center;
        justify-content: center;
        width: 100%;
        transition: .3s opacity ease-in-out;
        background-color: rgba(#252631, .5);
        font-size: 18px;
        color: #fff;
        opacity: 0;
        cursor: pointer;
      }

      &:hover button {
        opacity: 1;
      }

      & + & {
        margin-left: px-to-rem(8px);
      }
    }

    &__button {
      position: relative;
      width: 100%;
      text-align: center;

      label {
        display: block;
      }

      &-icon {
        margin-right: 6px;
      }
    }

    &__button--uploaded {
      width: auto;
      margin-left: px-to-rem(12px);
      padding-left: px-to-rem(12px);
      text-align: left;

      label {
        display: inline-block;
      }

      &::before {
        @include absolute(left 0 top 50%);
        width: 1px;
        height: 24px;
        margin-top: -12px;
        background-color: #DEE1E4;
        content: '';
      }
    }

    &__button--disabled {
      cursor: not-allowed;

      label {
        color: #AFB9CE;
      }
    }
    &--card {
      display: flex;
      flex-direction: column;
      .preview__images {
        width: 100%;
        .preview__image {
          padding: 8px;
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          margin-bottom: 8px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
          border-radius: 3px;
          button {
            @include absolute(right 8px);
            @extend %flex-center;
            left: auto;
            justify-content: center;
            background-color: transparent;
            font-size: 18px;
            color: #ccc;
            opacity: 1;
            cursor: pointer;
            width: auto;
          }
          img {
            object-fit: cover;
            width: 52px;
            max-height: 40px;
          }
          .image-name {
            max-width: 80%;
            word-break: break-all;
            margin-left: 12px;
          }
          .pdf-placeholder {
            width: 52px;
            height: 40px;
            overflow: hidden;
          }
        }
      }
      .preview__button {
        width: 100%;
        margin: 0;
        padding: 0;
        label {
          width: 100%;
          text-align: center;
        }
        &::before {
          content: none;
        }
      }
    }
  }

  .label {
    @extend %gray-label;
    display: block;
    margin: px-to-rem(14px) 0 px-to-rem(6px) 0;
  }

  input {
    display: none;
  }

  .disabled {
    pointer-events: none;
  }
  .error {
    margin-top: .5rem;
    color: #EA4B5D;
    @include font-size(12px);
  }
</style>
