<template>
  <div :class="['input-group', { disabled }, { error: serverError || clientError }]">
    <label class="input-group__label" :for="id">{{ label }}</label>
    <textarea
      :id="id"
      :name="name"
      :placeholder="placeholder"
      @input="onChange"
      v-model="val"
      :disabled="disabled"
      :required="required"
      :readonly="readonly"
      :autocomplete="autocomplete"
      class="input-group__textarea"
      v-validate="validators"
      :title="tooltip || (disabled && 'You might not have permission to edit this field.')"
      data-vv-delay="500"
      :data-cy="dataCy"
      :rows="rows"
    ></textarea>
    <div v-if="hasClientError" class="input-group__error">{{ clientError }}</div>
    <div v-if="hasServerErrorMessage" class="input-group__error">{{ serverError.message }}</div>
    <div v-else-if="$slots.help" class="input-group__help">
      <!-- @slot Use this slot to show field help -->
      <slot name="help"></slot>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import { mapMutations } from 'vuex';

/**
 * Form textarea used around the website.
 * Also able to get its error messages from the vuex state based on the `formKey` and `name` props.
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  /**
   * Name is explicitly needed here because it will be used to
   * dynamically restart inputs inside ezForm
   * @see src/components/ui/Form/EzForm.vue
   */
  name: 'eztextarea',
  props: {
    /**
     * Key from the parent form
     */
    formKey: {
      required: true,
      type: String,
    },
    /**
     * Input name
     */
    name: {
      required: true,
      type: String,
    },
    /**
     * Textarea value
     */
    value: {
      required: false,
      default: '',
      type: [Number, String],
    },
    /**
     * Label that is above the textarea.
     */
    label: {
      required: false,
      type: String,
    },
    /**
     * Placeholder text inside the input.
     */
    placeholder: {
      required: false,
      type: String,
    },
    /**
     * Tooltip text, describes input's purpose or state on hover
     */
    tooltip: {
      required: false,
      type: String,
    },
    /**
     * If the input is disable or not.
     */
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the input is readonly or not.
     */
    readonly: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * If the input is required or not.
     */
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     *
     */
    validators: {
      required: false,
      type: [Object, String, Function],
    },
    autocomplete: {
      type: String,
    },
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
    rows: {
      required: false,
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      id: null,
      val: '',
    };
  },
  computed: {
    serverError() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    clientError() {
      return this.errors.first(this.name);
    },
    hasServerErrorMessage() {
      return (
        this.serverError &&
        typeof this.serverError === 'object' &&
        Object.prototype.hasOwnProperty.call(this.serverError, 'message')
      );
    },
    hasClientError() {
      return !!this.clientError;
    },
    validationsState() {
      return this.fields[this.name];
    },
  },
  methods: {
    // ...
    onChange() {
      if (this.hasServerErrorMessage) {
        this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
      }

      /**
       * When textarea value is changed.
       * @event onChange
       * @type String
       */
      this.$emit('onChange', this.val);
    },
    reset() {
      this.val = '';
    },
    ...mapMutations('errors', ['CLEAR_ERROR']),
  },
  watch: {
    validationsState: {
      deep: true,
      handler(newValue, oldValue) {
        /**
         * When validation state is changed.
         * @event onChange
         * @type Object
         */
        return this.$emit('validationChange', newValue, oldValue);
      },
    },
    value: {
      immediate: true,
      handler(newVal, oldVal) {
        this.val = newVal;

        if (newVal && oldVal && newVal !== oldVal) {
          this.onChange();
        }
      },
    },
  },
  mounted() {
    this.id = uuid();
  },
};
</script>

<style lang="scss" scoped>
$label-color: #6c7995;
$label-font-size: 12px;
$label-line-height: 14px;
$label-letter-spacing: 0.025rem;

$input-color: #252631;
$input-border-color: #dee1e4;
$input-border-error-color: #ea4b5d;
$input-height: 2.25rem;
$input-border-radius: 0.1875rem;
$input-focus-border: 0.125rem solid #4d7cfe;
$input-padding: 0.5rem;
$input-disabled-bg-color: #e8ecf7;
$input-disabled-color: #b4b8c2;
$input-border-color-active: #4d7cfe;

$slot-color: #252631;

.input-group {
  text-align: left;
  &__label {
    display: block;
    margin-bottom: 0.375rem;
    color: $label-color;
    @include font-size($label-font-size, $label-line-height);
    font-weight: 500;
    text-transform: capitalize;
  }
  &__textarea {
    @include font-size(14px);
    @extend %input-reset;
    width: 100%;
    min-height: 100px;
    padding: $input-padding;
    border-radius: $input-border-radius;
    border: 1px solid $input-border-color;
    background-color: #ffffff;
    color: $input-color;
    font-weight: 400;
    outline: none;
    resize: none;
    &:not(:read-only):focus {
      border: $input-focus-border;
    }
    &:read-only,
    &:disabled {
      background-color: $input-disabled-bg-color;
      color: $input-disabled-color;
      cursor: not-allowed;
      &::placeholder {
        color: transparent;
      }
    }
  }
  &__error {
    margin-top: 0.5rem;
    color: $input-border-error-color;
    @include font-size(12px);
  }
  &__help {
    @include font-size(12px);
    margin-top: 0.5rem;
    color: $label-color;
  }
  &.disabled {
    .input-group__input {
      background-color: $input-disabled-bg-color;
    }
  }
  &.error {
    .input-group {
      &__input {
        border-color: $input-border-error-color;
      }
      &__label {
        color: $input-border-error-color;
      }
    }
  }
}
</style>
