<template>
  <div class="ez-wizard">
    <ez-loader :show="isLoading">{{ loadingMsg }}</ez-loader>
    <header>
      <div class="ez-wizard__logo">
        <router-link :to="{ name: home }">
          <img
            v-if="oezPlatformName === 'Foodrazor'"
            src="@/assets/Foodrazor-logo.svg"
            alt="Foodrazor logo"
          />
          <img v-else src="@/assets/logo-dark.svg" alt="EZ logo" />
        </router-link>
        <div class="ez-wizard__title">
          <slot name="title"></slot>
        </div>
      </div>
      <nav :class="['ez-wizard__nav', { 'ez-wizard__nav--hide': isLastStep }]">
        <slot name="nav"></slot>
      </nav>
      <div class="ez-wizard__actions">
        <slot name="actions"></slot>
        <div class="ez-wizard__exit">
          <button @click="exit">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <!-- eslint-disable-next-line max-len -->
              <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"
              />
            </svg>
            <small>ESC</small>
          </button>
        </div>
      </div>
    </header>
    <main
      :class="[
        'ez-wizard__main',
        { 'ez-wizard__main--wide': extraWide },
        { 'ez-wizard__main--medium': mediumWide },
      ]"
    >
      <div class="ez-wizard__page-banner">
        <slot name="pageBanner"></slot>
      </div>
      <div
        :class="[
          'ez-wizard__main-container',
          { 'ez-wizard__main-container--narrow': !fullWidth && !extraWide && !mediumWide },
          { 'ez-wizard__main-container--wide': extraWide },
          { 'ez-wizard__main-container--medium': mediumWide },
        ]"
      >
        <div class="ez-wizard__page-header">
          <div class="ez-wizard__page-title">
            <div class="ez-wizard__prev-step">
              <slot name="prevStep"></slot>
            </div>
            <h2>
              <slot name="pageTitle"></slot>
            </h2>
          </div>
          <p>
            <slot name="pageInfo"></slot>
          </p>
        </div>
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script>
import EzLoader from '@/components/ui/Loader/EzLoader';
import { mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: { EzLoader },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    extraWide: {
      type: Boolean,
      default: false,
    },
    mediumWide: {
      type: Boolean,
      default: false,
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingMsg: 'Loading...',
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    home() {
      const isAdmin = localStorage.getItem('role') === 'admin';

      return isAdmin ? 'admin-home' : 'platform-home';
    },
    isLoading() {
      return (
        this.getLoading(LOADING_KEY.FETCH_DISTRIBUTOR) ||
        this.getLoading(LOADING_KEY.FETCH_PRODUCTS) ||
        this.getLoading(LOADING_KEY.DISTRIBUTOR_NEW_ORDER_FETCH_PRODUCT_PRICE)
      );
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    exit() {
      this.$emit('exit');
    },
    onKeyUp(event) {
      if (event.key === 'Escape') {
        this.exit();
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyUp);
  },
  beforeDestroy() {
    window.addEventListener('keyup', this.onKeyUp);
  },
};
</script>

<style scoped lang="scss">
$content-width: 460px;
$content-width-wide: 57.5rem;
$content-width-medium: 700px;
$content-width-extra-wide: 75rem;

.ez-wizard {
  &__logo {
    @extend %flex-center;

    > a {
      display: block;
      height: 1.5rem;
      margin-right: 0.75rem;
      padding-right: 0.75rem;
      border-right: 1px solid #e9ebed;
    }
  }

  &__title {
    @include font-size(14px);
    color: $color-gray-6C;
    font-weight: 400;
  }

  &__page-banner {
    max-width: 552px;
    margin: 0 auto;
  }

  &__page-header {
    margin: 0 auto 1.5rem auto;
    max-width: $content-width;
    text-align: center;

    h2 {
      @include font-size(24px, 32px);
      font-weight: 500;
      margin: 0 auto;
    }

    p {
      @include font-size(14px, 22px);
      color: $color-gray-6C;
      margin: 0.5rem 0 0 0;
    }
  }

  &__page-title {
    @extend %flex-center;
    text-align: center;
  }

  &__prev-step {
    position: absolute;
    left: 0;

    .button--link {
      @include font-size(12px);
      font-weight: 400;
      padding-left: 12px;

      :deep() svg {
        margin-right: 0.375rem;
      }
    }
  }

  &__actions {
    @extend %flex-center;
    button {
      min-height: 32px;
    }
  }

  &__exit {
    @extend %flex-center;

    &:before {
      content: '';
      height: 1.5rem;
      margin-left: 1rem;
      padding-left: 1rem;
      border-left: 1px solid #dee1e4;
    }

    button {
      @extend %flex-center;
      flex-direction: column;
      cursor: pointer;
      min-height: auto;

      svg {
        fill: $color-gray-6C;
        display: block;
      }

      small {
        @include font-size(9px);
        color: $color-gray-6C;
        font-weight: 500;
      }
    }
  }

  &__main-container {
    margin: 0 auto;

    &--narrow {
      max-width: $content-width;
    }

    &--medium {
      max-width: $content-width-medium;
    }

    &--wide {
      max-width: $content-width-extra-wide;
    }
  }

  header {
    @extend %flex-center;
    justify-content: space-between;
    height: 4rem;
    margin: 0 2.5rem;
    border-bottom: 1px solid #dee1e4;
    position: relative;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    @include z-index('wizzard-header');

    img {
      height: 100%;
      max-width: 100%;
    }

    ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      padding: 0;

      li {
        @extend %flex-center;
        @include font-size(14px);
        justify-content: center;
        color: #a1a9bb;
        font-weight: 400;
        width: 7.5rem;
        position: relative;
        flex-shrink: 0;

        + li {
          margin-left: 1rem;
        }

        &:after {
          position: absolute;
          top: 2.125rem;
          left: 50%;
          transform: translateX(-50%);
          content: '';
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background-color: $color-gray-C4;
        }

        &.ez-step-completed,
        &.ez-step-active {
          &:before {
            content: '';
            background-color: $color-primary-blue;
            height: 1px;
            width: 8rem;
            position: absolute;
            bottom: 0;
            top: 2.45rem;
            left: -6px;
            transform: translateX(-50%);
            transform-origin: left center;
          }
          &:first-child:before {
            content: none;
          }
        }

        &.ez-step-active {
          color: $color-primary-blue;

          &:after {
            background-color: $color-white;
            border: 3px solid $color-primary-blue;
          }
        }

        &.ez-step-completed {
          color: $color-gray-25;

          &:after {
            background-color: $color-primary-blue;
          }
        }
      }
    }

    nav {
      @include absolute(top 50% left 50%);
      transform: translate(-50%, -50%);
    }

    .button--primary {
      width: auto;
      min-width: 8.5rem;
    }
  }

  &__main {
    margin: 0 auto;
    padding: 4em 12px;
    max-width: $content-width-wide;
    position: relative;

    &--wide {
      max-width: $content-width-extra-wide;
    }

    &--medium {
      max-width: $content-width-medium;
    }
  }
}

@media (max-width: 1024px) {
  .ez-wizard {
    header {
      flex-wrap: wrap;
      height: auto;
    }

    header nav {
      position: static;
      flex: 1 0 100%;
      order: 3;
      transform: none;
      padding: 8px 0;
    }
    &__actions,
    &__logo {
      padding: 5px 0;
    }
  }
}
@media (max-width: 768px) {
  .ez-wizard {
    &__nav--hide {
      display: none;
    }
    header {
      margin: 0;
      padding: 0 24px;

      .button--primary {
        min-width: initial;
        white-space: nowrap;
      }

      nav {
        max-width: 50%;
        order: 2;

        ul {
          text-align: left;
          justify-content: flex-start;
          overflow: hidden;

          li {
            display: none;
            &::after {
              content: none;
            }

            + li {
              margin-left: 0;
            }
          }
          .ez-step-active {
            display: block;
            color: #000;
          }
        }
      }
    }
    main {
      margin-top: 24px;
    }
    &__logo {
      display: flex;
      justify-content: space-between;
      flex: 1 0 100%;
      padding: 10px 24px;
      border-bottom: 1px solid #dee1e4;
      margin: 0 -24px;

      a {
        border-right: 0;
      }
    }
    &__actions {
      flex: 1 0 50%;
      display: flex;
      justify-content: flex-end;
      width: 50%;
      order: 3;
    }
    &__page-title {
      flex-direction: column;
    }
    &__prev-step {
      position: static;
    }

    &__main {
      padding: 12px 24px;
    }
  }
}
@media (max-width: 556px) {
  .ez-wizard {
    &__main-container,
    &__page-banner {
      margin: 0;
    }
  }
}

:deep() .loader {
  @extend %whole-screen;
}
</style>
