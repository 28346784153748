var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({ref:"select",staticClass:"ez-select",class:[{'ez-select--full-width': _vm.isFullWidth }, {'ez-select--disabled' : _vm.disabled}],attrs:{"data-cy":_vm.dataCy}},!_vm.disabled ? { click: _vm.toggle } : {}),[(_vm.label)?_c('label',{staticClass:"ez-select__label",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('select',{staticClass:"ez-select__select",attrs:{"disabled":_vm.disabled,"name":_vm.name,"id":_vm.id}},_vm._l((_vm.options),function(option){return _c('option',{key:option[_vm.valueField],domProps:{"value":option[_vm.valueField],"selected":_vm.selectedOption && option[_vm.valueField] === _vm.selectedOption[_vm.valueField]}},[_vm._v(" "+_vm._s(option[_vm.nameField])+" ")])}),0),_c('div',{class:[
      'ez-select__display-container',
      { border : _vm.expanded },
    ]},[_vm._t("display",function(){return [_c('div',{class:[
        'ez-select__display',
        {'ez-select__display--placeholder': !_vm.selectedOption[_vm.valueField]}
      ]},[_vm._v(_vm._s(_vm.selectedOption && _vm.selectedOption[_vm.nameField]))]),(_vm.expanded)?_c('font-awesome-icon',{attrs:{"icon":"angle-up"}}):_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})]})],2),_c('div',{staticClass:"ez-select__dropdown-container"},[(_vm.expanded)?_c('div',{staticClass:"ez-select__dropdown"},[_vm._t("default",function(){return _vm._l((_vm.options),function(option){return _c('ez-option',{key:option[_vm.valueField],attrs:{"option":option,"selected":_vm.selectedOption && option[_vm.valueField] === _vm.selectedOption[_vm.valueField]}},[_vm._v(" "+_vm._s(option[_vm.nameField])+" ")])})})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }