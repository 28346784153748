<script>
import VEntityInfo from '@/components/v3/elements/VEntityInfo';
import EzPremiumBadge from '@/components/ui/PremiumBadge';

export default {
  components: {
    EzPremiumBadge,
    VEntityInfo,
  },
  props: {
    distributor: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    showPremium: {
      type: Boolean,
      required: false,
      default: true,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    /**
     * A rare case when the request is not sent and UI is initialized.
     * Most often happens on IE 11.
     * @return {*|{}}
     */
    entity() {
      return this.distributor || {};
    },
    hasPremiumBadge() {
      return this.showPremium && this.$helpers.isPremium(this.entity.accountType);
    },
  },
};
</script>
<template>
  <v-entity-info :imageUrl="entity.logo" imageBorderRadius="50%" :imageHasBorder="true">
    <span :data-cy="dataCy">{{ entity.name }}</span>
    <template #suffix>
      <ez-premium-badge v-if="hasPremiumBadge" />
    </template>
  </v-entity-info>
</template>
